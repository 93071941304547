import React from 'react';
import { createUseStyles } from 'react-jss';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  image: {
    position: 'relative',
    width: 600,
  },
});

const content1 = [
  'Stay organized. Never lose a contact again.',
  'Store, manage, and label your leads, contacts, and clients in one central online hub.',
  'Know your clients inside and out.',
  'Organize your customers with groups.',
];
const content2 = [
  'Import your existing customer data for free and securely.',
  'Performed for you by our customer support team.',
  'Or, do-it-yourself and stay in total control of your data.',
];
const content3 = [
  'Use the activity log to keep a detailed history of your relationship with the customer.',
  'Use the customer directory to facilitate customer re-marketing campaigns.',
  'Understand how your customers interact with your business.',
];
const content4 = [
  'Connect your customer list with email marketing, chat, appointment booking, and online payments.',
  'Integrate with external platforms such as Facebook, Google, and Quickbooks.',
];

const BuiltSection: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <RightLeftChecklistSection
        title="Built For Businesses Just Like You"
        rightContent={{
          title: 'Manage Your Customers in One Place',
          checklist: content1,
          media: (
            <img
              width={600}
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/product-images/CustomerListBuilt1.svg"
              alt="Customer list product"
            />
          ),
        }}
        sectionContainerStyleOverrides={{
          paddingBottom: 0,
        }}
        leftShapeColorOverride="#F5D545"
        leftContent={{
          title: 'Managing customers elsewhere? We can securely migrate your client data',
          checklist: content2,
          media: (
            <img
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/product-images/CustomerListBuilt2.svg"
              alt="Customer list product"
            />
          ),
        }}
      />
      <RightLeftChecklistSection
        excludeTopPadding
        sectionContainerStyleOverrides={{
          paddingTop: 0,
        }}
        rightContent={{
          title: 'Manage Your Customers in One Place',
          checklist: content3,
          media: (
            <img
              width={600}
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/product-images/CustomerListBuilt3.svg"
              alt="Customer list product"
            />
          ),
        }}
        rightShapeColorOverride="#00A6ED"
        leftContent={{
          title: 'Managing customers elsewhere? We can securely migrate your client data',
          checklist: content4,
          media: (
            <img
              className={classes.image}
              src="https://assets.bizwise.com/landing-ui/product-images/CustomerListBuilt4.svg"
              alt="Customer list product"
            />
          ),
        }}
      />
    </>
  );
};

export default BuiltSection;
