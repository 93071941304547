import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/CustomerList/Hero';
import DetailsSection from 'src/sections/CustomerList/Details';
import BuiltSection from 'src/sections/CustomerList/Built';
import FeaturesSection from 'src/sections/CustomerList/Features';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import ApiWrapper from 'src/components/ApiWrapper';
import GetStarted from 'src/sections/CustomerList/GetStarted';
import TestimonialsSection from 'src/sections/shared/Testimonials';

import { StaticImage } from 'gatsby-plugin-image';

const testimonialsAvatarSizePx = 50;
const testimonialsAssetBase = 'https://assets.bizwise.com/landing-ui/testimonials';
const testimonials = [
  {
    name: 'Travis Seminara',
    label: 'Smacktone Studios',
    content: 'The customer list app helped my business organize and keep track of all of my customers!',
    avatar: <StaticImage src={`${testimonialsAssetBase}/6.jpg`} alt="Travis Seminara" width={testimonialsAvatarSizePx} />,
  },
  {
    name: 'Ken Demers',
    label: 'Demers Law PC',
    content: 'I use the Customer List to maintain a log of all of my interactions with my clients, and it did a great job at that!',
    avatar: <StaticImage src={`${testimonialsAssetBase}/4.jpg`} alt="Ken Demers" width={testimonialsAvatarSizePx} />,
  },
  {
    name: 'Manisha Sharma',
    content: 'The customer list from Bizwise is a massive time saver, and was much simpler than the CRM I used before.',
    avatar: <StaticImage src={`${testimonialsAssetBase}/2.jpg`} alt="Manisha Sharma" width={testimonialsAvatarSizePx} />,
  },
];

const CustomerListProductPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="The Customer List" />
      <HeroSection />
      <DetailsSection />
      <BuiltSection />
      <FeaturesSection />
      <TestimonialsSection
        isLight={false}
        showBackgroundImage={false}
        testimonials={testimonials}
      />
      <FaqSection types={[FaqTypes.CUSTOMER_LIST_MODULE]} />
      <GetStarted />
    </Layout>
  </ApiWrapper>
);

export default CustomerListProductPage;
