import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: spacing(8),
    marginBottom: spacing(10),
    [breakpoint.mobile()]: {
      marginBottom: spacing(10),
    },
  },
  grid: {
    columnGap: spacing(30),
    rowGap: spacing(8),
    [breakpoint.down('sm')]: {
      columnGap: spacing(5),
      rowGap: spacing(5),
    },
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: spacing(4),
    height: pxToRem(60),
    [breakpoint.mobile()]: {
      height: pxToRem(50),
    },
  },
});

const features: {
  iconSrc: string,
  label: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/OnlinePaymentsIcon.svg'),
    label: 'Online Payments',
  },
  {
    iconSrc: assetLink('landing-ui/icons/ChatIcon.svg'),
    label: 'Live Chat',
  },
  {
    iconSrc: assetLink('landing-ui/icons/EcommerceIcon.svg'),
    label: 'Ecommerce',
  },
  {
    iconSrc: assetLink('landing-ui/icons/SchedulingIcon.svg'),
    label: 'Appointment\nScheduling',
  },
  {
    iconSrc: assetLink('landing-ui/icons/BookingIcon.svg'),
    label: 'Customer\nBooking',
  },
  {
    iconSrc: assetLink('landing-ui/icons/FormsIcon.svg'),
    label: 'Forms &\nQuestionaires',
  },
  {
    iconSrc: assetLink('landing-ui/icons/ActivityLogIcon.svg'),
    label: 'Activity Log',
  },
  {
    iconSrc: assetLink('landing-ui/icons/AnalyticsIcon.svg'),
    label: 'Analytics & Tag\nMangager',
  },
  {
    iconSrc: assetLink('landing-ui/icons/NewsletterIcon.svg'),
    label: 'Newsletter &\nMailing List',
  },
];

interface Props {
  businessType?: string,
}

const Features: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      background="light-blue"
      className={classes.container}
    >
      <Typography variant="h2" color="blue" centered underline style={{ maxWidth: pxToRem(970) }}>
        Unlock A Universe of Possibilities With Your Customer List
      </Typography>
      <Typography
        variant="p1"
        color="blue"
        centered
        className={classes.text}
        style={{
          maxWidth: pxToRem(600),
        }}
      >
        Find amazing apps that you can use with your customer list
      </Typography>
      <div className={cx(classes.grid, 'grid grid-cols-3')}>
        {features.map(({ iconSrc, label }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} className={classes.icon} alt={`${label} Icon`} />
            <Typography variant="p3" color="blue" centered style={{ fontWeight: 600 }}>
              {label}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Features;
